import React, { Component } from 'react';
import classNames from 'classnames';

import loadingText from './LoadingText';

interface Props {
    isLoggedIn?: boolean;
    chatEnabled: boolean;
    /** Whether or not the chat is available */
    chatIsOpen: boolean;
    /** An awaitable function that returns a SAML-token. Required when `isLoggedIn` is true */
    loadSamlToken: () => Promise<string>;
    /** Required when `isLoggedIn` is true */
    loggedInChatUrl: string;
    onChatEnded: () => void;
    onChatStarted: () => void;
    onChatWindowHidden: () => void;
    onChatWindowShown: () => void;
    /** Required when `isLoggedIn` is false */
    publicChatUrl: string;
}

interface State {
    isHidden: boolean;
    isOpen: boolean;
}

class ChatWindow extends Component<Props, State> {
    _iframe: HTMLIFrameElement | null;
    static defaultProps = {
        onChatEnded: (f) => f,
        onChatStarted: (f) => f,
        onChatWindowHidden: (f) => f,
        onChatWindowShown: (f) => f,
    };

    constructor(props: Props) {
        super(props);

        this.onToggleHidden = this.onToggleHidden.bind(this);
        this.onToggleOpen = this.onToggleOpen.bind(this);
        this._iframe = null;
        this.state = {
            isHidden: false,
            isOpen: false,
        };
    }

    async componentDidMount() {
        const {
            isLoggedIn,
            loadSamlToken,
            loggedInChatUrl,
            publicChatUrl,
        } = this.props;

        if (isLoggedIn) {
            if (!loadSamlToken) {
                throw new Error('For bank customers, a "loadSamlToken" function must be provided to ChatWindow');
            }
            if (!loggedInChatUrl) {
                throw new Error('For bank customers, a "loggedInChatUrl" string must be provided to ChatWindow');
            }
        }

        if (!publicChatUrl) {
            throw new Error('For non-bank customers, a "publicChatUrl" string must be provided to ChatWindow');
        }
    }

    async componentDidUpdate(_prevProps, prevState) {
        const {
            isLoggedIn,
            loadSamlToken,
            loggedInChatUrl,
        } = this.props;

        if (!prevState.isOpen && this.state.isOpen) {
            // The iframe has just mounted since the state went from not open to open

            if (isLoggedIn && this._iframe?.contentWindow) {
                const response = await loadSamlToken();

                const frameDocument = this._iframe.contentWindow.document; // eslint-disable-line

                const form = frameDocument.createElement('form');
                const saml = frameDocument.createElement('input');
                const authType = frameDocument.createElement('input');
                const operationType = frameDocument.createElement('input');

                form.method = 'POST';
                form.action = loggedInChatUrl;

                saml.value = response;
                saml.name = 'SSOResponse';
                saml.type = 'hidden';
                form.appendChild(saml);

                authType.value = '1';
                authType.name = 'AuthenticationType';
                authType.type = 'hidden';
                form.appendChild(authType);

                operationType.value = 'SSOAuth';
                operationType.name = 'OP';
                operationType.type = 'hidden';
                form.appendChild(operationType);

                frameDocument.body.appendChild(form);
                form.submit();
            }
            setTimeout(() => this._iframe && this._iframe.focus());
        }
    }

    onToggleHidden() {
        if (!this.state.isHidden) {
            this.props.onChatWindowHidden();
        }
        if (this.state.isHidden) {
            this.props.onChatWindowShown();
        }
        // eslint-disable-next-line react/no-is-mounted
        this.setState((prevState) => ({
            isHidden: !prevState.isHidden,
        }));
    }

    onToggleOpen() {
        if (this.state.isOpen) {
            this.props.onChatEnded();
        }
        if (!this.state.isOpen) {
            this.props.onChatStarted();
        }
        // eslint-disable-next-line react/no-is-mounted
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
    }

    render() {
        const {
            chatEnabled,
            chatIsOpen,
            isLoggedIn,
            publicChatUrl,
        } = this.props;

        const {
            isHidden,
            isOpen,
        } = this.state;

        const isChatOpen = chatEnabled && chatIsOpen;

        if (!isChatOpen) {
            return null;
        }

        const textWrapper = (children) =>
            (
                <text className="ppm-chat-window__ticking-dots">
                    {children}
                </text>
            );

        return (
            <div
                className={classNames(
                    'ppm-chat-window',
                    { 'ppm-chat-window--closed': !isOpen || isHidden },
                )}
            >
                {isOpen && !isHidden && (
                    <button
                        className="ffe-link-text ppm-chat-window__close-button"
                        onClick={this.onToggleOpen}
                        type="button"
                    >
                        Avslutt
                    </button>
                )}
                <button
                    aria-label="Vis eller skjul chat"
                    className="ppm-round-icon"
                    onClick={isOpen ? this.onToggleHidden : this.onToggleOpen}
                    type="button"
                >
                    <svg
                        viewBox="0 0 200 200"
                        width="150"
                        height="150"
                        y="1500"
                    >
                        {loadingText('', isOpen && isHidden, textWrapper, 3)}
                        {/* eslint-disable max-len */ }
                        <path
                            className={classNames(
                                'ppm-chat-window__icon-bubble',
                                { 'ppm-chat-window__icon-bubble--open': isOpen && !isHidden },
                            )}
                            d="m54.8 2.1c-29.3 0-52.6 22.8-52.6 51.3l0 46.7c0 28.5 23.3 51.3 52.2 51.3l0 37c0 5.3 6.3 10.4 12.9 10.4 2.9 0 6.2-1.1 8.7-3.2l53.8-44.2 16.4 0c29.3 0 52.6-22.8 52.6-51.3l0-47C198.5 25.3 175.1 2.1 146.2 2.1l-91.4 0zm-0.5 17.4 91.9 0c19 0 34.7 15.3 34.7 33.8l0 46.7c0 18.5-15.7 33.8-34.7 33.8l-22.7 0-5.1 4.2-46.5 37.8 0-42-17.9 0c-19 0-34.4-15.3-34.4-33.8l0-46.7c0-18.5 15.7-33.8 34.7-33.8z"
                        />
                        {/* eslint-enable max-len */ }
                        <line
                            className={classNames(
                                'ppm-chat-window__icon-line1',
                                { 'ppm-chat-window__icon-line1--open': isOpen && !isHidden },
                            )}
                            y1="100"
                            x1="10"
                            x2="190"
                            y2="100"
                            strokeWidth="1"
                        />
                    </svg>
                </button>
                <div
                    className={classNames(
                        'ppm-chat-window__frame-wrapper',
                        { 'ppm-chat-window__frame-wrapper--open': isOpen && !isHidden },
                    )}
                >
                    {isOpen && !isLoggedIn && (
                        <iframe
                            aria-hidden={isHidden}
                            className="ppm-chat-window__frame"
                            frameBorder="0"
                            ref={(_iframe) => { this._iframe = _iframe; }}
                            src={publicChatUrl}
                            title="SpareBank 1 Forsikring kundechat"
                        />
                    )}
                    {isOpen && isLoggedIn && (
                        <iframe
                            aria-hidden={isHidden}
                            className="ppm-chat-window__frame"
                            frameBorder="0"
                            ref={(_iframe) => { this._iframe = _iframe; }}
                            src="about:blank"
                            title="SpareBank 1 kundechat"
                        />
                    )}
                </div>
                <div
                    className={classNames(
                        'ppm-chat-window__frame-footer',
                        { 'ppm-chat-window__frame-footer--open': isOpen && !isHidden },
                    )}
                >
                    {/*
                        An empty div does not occupy any space and this footer needs to
                        do that in order to hide the page that's under the chatwindow.
                        The non breaking space seemed to be the only way to ensure that.
                    */}
                    &nbsp;
                </div>
            </div>
        );
    }
}
export default ChatWindow;
