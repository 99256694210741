import React, {PropsWithChildren} from 'react';
import Login from '~/components/signering/Login';
import {useInnloggetBruker} from '~/components/felles/InnloggetBruker';

const Innlogget: React.FunctionComponent<PropsWithChildren> = ({ children }) => {

    const { innlogget } = useInnloggetBruker();
    if (!innlogget) {
        return <Login />
    }
    return <>{children}</>
};

export default Innlogget;
