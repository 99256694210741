import {HjelpetekstProps} from '~/components/produkt/Hjelpetekst';

export const hjelpFodselsnummer: HjelpetekstProps = {
    tittel: 'Fødselsnummer',
    innhold: ['Fra fødselsnummeret hentes informasjon om alder og kjønn som brukes i prisberegningen. D-nummer kan også brukes for personer som ikke har fødselsnummer.']
};

export const hjelpArslonn: HjelpetekstProps = {
    tittel: 'Årslønn før skatt',
    innhold: ['Det er lønn i forhold til stillingsprosent som skal registreres. Hvis den ansatte jobber i 80% stilling, er det lønn i 80% stilling som registreres. Ansatte som jobber i 100% stilling skal ha 100% lønn.', 'Beløpet kan endres senere i kundeportalen.']
};

export const hjelpArbeidsdyktig: HjelpetekstProps = {
    tittel: '100 % arbeidsdyktig',
    innhold: ['Med 100 % arbeidsdyktig menes at den ansatte er frisk, dvs ikke er sykmeldt eller har redusert arbeidsevne, og er til stede på jobb uten helsemessige restriksjoner eller tilrettelegginger. Dette gjelder også ansatte i deltidsstilling.']
};

export const hjelpStillingsprosent: HjelpetekstProps = {
    tittel: 'Stillingsprosent',
    innhold: ['Oppgi stillingsprosent i hele tall.']
};
