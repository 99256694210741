import React, {useRef, useState} from 'react';
import ErrorHandler from '~/components/felles/ErrorHandler';
import Routing from '~/Routing';
import ScrollToTop from '~/components/felles/ScrollToTop';

const Hovedflyt: React.FunctionComponent = () => {
    const kontaktSkjemaRef = useRef<HTMLDivElement>(null);
    const [kontaktskjemaApent, setKontaktskjemaApent] = useState<boolean>(false);

    const apneKontaktskjema = () => {
        if (!kontaktskjemaApent) {
            setKontaktskjemaApent(true);
        }
        setTimeout(() => {
            if (kontaktSkjemaRef.current) {
                window.scrollTo(0, kontaktSkjemaRef.current.offsetTop);
            }
        }, 300);
    }

    const lukkKontaktskjema = () => {
        if (kontaktskjemaApent) {
            setKontaktskjemaApent(false);
        }
    }

    return <ErrorHandler apneKontaktskjema={apneKontaktskjema} lukkKontaktskjema={lukkKontaktskjema}>
        <ScrollToTop>
            <Routing kontaktSkjemaRef={kontaktSkjemaRef} kontaktskjemaApent={kontaktskjemaApent} setKontaktskjemaApent={setKontaktskjemaApent}/>
        </ScrollToTop>
    </ErrorHandler>
}
export default Hovedflyt;
