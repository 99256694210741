import * as React from 'react';
import { trackClick } from '@sparebank1/pensjon-bm-analytics';

export interface TrackedButtonProps {
    children: React.ReactElement | React.ReactElement[];
    knappNavn: string | string[];
    steg?: string;
}

const TrackedButtons: React.FunctionComponent<TrackedButtonProps> = ({ children, knappNavn, steg }) => {
    const trackOgUtfor = (
        actionFunction: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        navn: string
    ) => {
        trackClick(steg ? `${steg}.${navn}` : navn);
        if (actionFunction) {
            actionFunction(event);
        }
    };

    function cloneButton(button: React.ReactElement<any>, navn) {
        return React.cloneElement(button, {
            onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) =>
                trackOgUtfor(button.props.onClick, event, navn)
        });
    }

    function renderChildButtons() {
        if (React.Children.count(children) > 1 && knappNavn instanceof Array) {
            let i = -1;
            return React.Children.map(children, (child: React.ReactElement<any>) => {
                i++;
                return cloneButton(child, knappNavn[i]);
            });
        } else {
            const button: React.ReactElement<any> = React.Children.only(children);
            return cloneButton(button, typeof knappNavn === 'string' ? knappNavn : knappNavn[0]);
        }
    }

    return (
        <>
            {renderChildButtons()}
        </>
    );
};

export default TrackedButtons;
