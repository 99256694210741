import React from 'react';
import { Grid, GridCol, GridRow } from '@sb1/ffe-grid-react';
import { Heading1, Heading2 } from '@sb1/ffe-core-react';
import AnsatteTabell from '~/components/ansatte/AnsatteTabell';
import Produkter from '~/components/signering/Produkter';
import { trackClick } from '@sparebank1/pensjon-bm-analytics';
import { Link } from 'react-router-dom';
import { ErrorFieldMessage } from '@sb1/ffe-form-react';
import useTilbud from '~/components/tilbud/useTilbud';

interface Props {
    dsfOk: boolean;
}
const TilbudOppsummering: React.FunctionComponent<Props> = ({ dsfOk }) => {
    const { tilbud } = useTilbud();
    return <Grid>
        <GridRow className={'ksmb--padding-top'}>
            <GridCol center={true}>
                <Heading1>Oppsummering og signering</Heading1>
            </GridCol>
            <GridCol center={true} className='ksmb--flex-column'>
                <Heading2>{tilbud.orgnavn}</Heading2>
                <AnsatteTabell ansatte={tilbud.ansatte} />
            </GridCol>
            <GridCol className={'ksmb--flex-column ksmb--flex-end'}>
                <Link to={`./endreansatte`} className="ffe-link-text"
                    onClick={() => trackClick('endre ansattdetaljer')}>
                    Endre ansattdetaljer
                </Link>
            </GridCol>
            {!dsfOk &&
                <GridCol center={true}>
                    <ErrorFieldMessage>Oppslag mot folkeregisteret viser at noen ansattdetaljer må korrigeres før du kan signere.</ErrorFieldMessage>
                </GridCol>
            }
            <GridCol>
                <Produkter />
            </GridCol>
        </GridRow>
    </Grid>
}
export default TilbudOppsummering;
