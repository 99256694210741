import {useContext, useEffect, useState} from 'react';
import { FormContext } from '~/Routing';
import { FormSubmitStatus, trackFormErrors } from '@sparebank1/pensjon-bm-analytics';

export type SkjemafeilType = [string | undefined, (s?: string) => void];

function useSkjemafeil(prefix: string): SkjemafeilType {
    const [feilmelding, setFeilmelding] = useState<string | undefined>();
    const formContext = useContext(FormContext);

    useEffect(() => {
        if (!feilmelding || feilmelding === '') {
            formContext.setFormStatus(FormSubmitStatus.SUCCESS);
        } else {
            const skjemaFeil = {};
            skjemaFeil[prefix] = feilmelding;
            trackFormErrors(skjemaFeil);
            formContext.setFormStatus(FormSubmitStatus.ERROR);
        }
    // eslint-disable-next-line
    }, [feilmelding]);

    return [feilmelding, setFeilmelding];
}

export default useSkjemafeil;
