import React, {useId} from 'react';
import {Collapse} from '@sb1/ffe-collapse-react';
import {Paragraph} from '@sb1/ffe-core-react';
import {ContextMessage} from "@sb1/ffe-messages-react";
import {formaterBelopKr} from "~/utils/uiUtils";

export interface HjelpetekstProps {
    innhold: string[];
    tittel?: string;
    visG?: boolean;
}

export interface Props {
    tekst?: HjelpetekstProps;
    vis: boolean;
    className?: string;
    gverdi?: number;
}

const gTekst = (g: number) => (
    `G er grunnbeløpet i folketrygden og er nå ${formaterBelopKr(g)}.`
);

export const Hjelpetekst: React.FunctionComponent<Props> = ({ vis, tekst, className, gverdi }) => {
    const compId = useId();
    return (
        <Collapse isOpen={vis} id={compId} className={className}>
            { tekst ?
                <ContextMessage
                    type={"tips"}
                    className={'ksmb-hjelpetekst'}
                    header={tekst.tittel}
                >
                    {tekst.innhold.map((tekst, i) => (<Paragraph key={i}>{tekst}</Paragraph>))}
                    {tekst.visG && gverdi &&
                        <Paragraph noMargin={true}>{gTekst(gverdi)}</Paragraph>
                    }
                </ContextMessage>
                :
                <></>
            }
        </Collapse>
    );
};
