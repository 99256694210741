import React from 'react';
import {sub} from "date-fns";
import {formatDate, forsteINesteManed} from '~/utils/datoUtil';

export interface FlytteSelskap {
    id: string;
    navn: string;
}

export const ANNET_SELSKAP = '-1';

export const flytteSelskaper: FlytteSelskap[] = [
    {id: '1', navn: 'Codan'},
    {id: '2', navn: 'Danica'},
    {id: '3', navn: 'DNB'},
    {id: '5', navn: 'Frende'},
    {id: '7', navn: 'Gjensidige'},
    {id: '8', navn: 'Handelsbanken'},
    {id: '9', navn: 'If'},
    {id: '10', navn: 'KLP'},
    {id: '11', navn: 'Nordea'},
    {id: '12', navn: 'Protector'},
    {id: '13', navn: 'Sandnes Sparebank'},
    {id: '14', navn: 'Skagen'},
    {id: '15', navn: 'Storebrand'},
    {id: '16', navn: 'Terra'},
    {id: '17', navn: 'Tryg'},
    {id: '18', navn: 'Duvi'},
    {id: ANNET_SELSKAP, navn: 'Finner ikke selskapet'}
];

export const inkrementerendeOptions = (start: number, slutt: number): React.ReactNode[] => {
    const options: React.ReactNode[] = [];
    for (let i = start; i <= slutt; i++) {
        options.push(<option key={i} value={i}>{i} %</option>);
    }
    return options;
};

export const andelAvLonnOptions = (): React.ReactNode[] => inkrementerendeOptions(2, 7);

export const tilleggssparingOptions = (): React.ReactNode[] => {
    const options: React.ReactNode[] = inkrementerendeOptions(0, 18);
    options.push(<option key={'18.1'} value={18.1}>18,1 %</option>);
    return options;
};

export const flytteSelskaperOptions = (): React.ReactNode[] => {
    const options: React.ReactNode[] = [];
    options.push(<option key="" value="" disabled>Velg selskap</option>);
    flytteSelskaper.sort((a, b) => a.navn.localeCompare(b.navn)).forEach((selskap: FlytteSelskap) => {
        options.push(<option key={selskap.id} value={selskap.id}>{selskap.navn}</option>);
    });
    return options;
};

export const startdatoOptions = (): React.ReactNode[] => {
    const options: React.ReactNode[] = [];
    const nesteManed = forsteINesteManed();
    for (let i = 0; i <= 19; i++) {
        const start = sub(nesteManed, { months: i});
        options.push(<option key={i} value={formatDate(start)}>{start.toLocaleDateString('no-NB', {year: 'numeric', month: 'long'})}</option>);
    }
    return options;
};
