import React from 'react';
import {Route, Routes} from 'react-router';
import EndreAnsattinformasjon from '~/components/ansatte/EndreAnsattinformasjon';
import Oppsummering from '~/components/oppsummering/Oppsummering';
import Produktspesifikasjoner from '~/components/produkt/Produktspesifikasjoner';

const OppsummeringRoutes: React.FunctionComponent = () => {
    return <Routes>
        <Route path={'/endreansatte'} element={<EndreAnsattinformasjon />} />
        <Route path={'/endreprodukt'} element={<Produktspesifikasjoner endreModus={true} />} />
        <Route path={'/'} element={<Oppsummering />} />
    </Routes>
}
export default OppsummeringRoutes;
