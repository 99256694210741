import React, { useContext, useEffect, useState } from 'react';
import { Grid, GridCol, GridRow } from '@sb1/ffe-grid-react';
import { Paragraph, StrongText } from '@sb1/ffe-core-react';
import Kontaktskjema, { KontaktMegInput } from '~/components/bliOppringt/Kontaktskjema';
import { useSettings } from '~/components/felles//Settings';
import { BliOppringtRequest, sendBliOppringt } from '~/components/bliOppringt/kontaktService';
import { TilbudContext } from '~/components/tilbud/TilbudContext';
import { FormSubmitStatus, Skjemadata, trackFormCompleted } from '@sparebank1/pensjon-bm-analytics';
import { useTrackPage } from '~/components/tracking/useTrackPage';
import { Icon } from "@sb1/ffe-icons-react";
import HakeIkon from "@sb1/ffe-icons/icons/open/500/xl/done.svg";

interface Props {
    feilmelding?: string | null;
}

export const SKJEMA: Skjemadata = {navn: 'Bli oppringt', type: '', skjematype: 'KSMB'};
export const FYLL_UT_KONTAKTINFORMASJON = {navn: 'Kontaktinformasjon', nummer: 1};

//TODO: Håndtere at sending feiler
const BliOppringt: React.FunctionComponent<Props> = ({ feilmelding }) => {
    const [sender, setSender] = useState<BliOppringtRequest | null>(null);
    const [fullfort, setFullfort] = useState<boolean>(false);
    const settings = useSettings();
    const tilbudContext = useContext(TilbudContext);
    const [erSkjemafeil, setErSkjemafeil] = useState<boolean>(false);
    const feilISkjemaet = React.useRef<boolean>(false);

    const sendBliOppringtSkjema = (values: KontaktMegInput) => {
        const req: BliOppringtRequest = {
            navn: values.navn,
            telefon: values.telefon,
            aarsak: feilmelding,
            tilbud: tilbudContext.tilbud
        };
        setSender(req);
    };

    useTrackPage(SKJEMA, FYLL_UT_KONTAKTINFORMASJON, () => {
        if (feilISkjemaet.current) {
            trackFormCompleted(FormSubmitStatus.ERROR);
        } else {
            trackFormCompleted(FormSubmitStatus.USER_CANCEL);
        }
    });

    useEffect(() => {
        feilISkjemaet.current = erSkjemafeil;
    }, [erSkjemafeil]);

    useEffect(() => {
        if (sender !== null) {
            sendBliOppringt(sender);
            setFullfort(true);
            trackFormCompleted(FormSubmitStatus.SUCCESS);
        }
    }, [sender]);

    return (
        <Grid className="ksmb-kontaktskjema-grid">
            <GridRow>
                <GridCol md={{ offset: 2, cols: 8 }} lg={{ offset: 3, cols: 6 }}
                    center={fullfort}>
                    {!fullfort &&
                        <Kontaktskjema feilmelding={feilmelding}
                            settings={settings}
                            utforSendBliOppringt={sendBliOppringtSkjema}
                            setErSkjemafeil={setErSkjemafeil}
                            sender={sender !== null} />
                    }
                    {fullfort && sender &&
                        <div className={'ksmb-kontaktskjema-kvittering'}>
                            <div className="ksmb-icon-circle ksmb-icon-circle--farge-skog ksmb-icon-circle--margins">
                                <Icon fileUrl={HakeIkon} size={"xl"} className={"ksmb-icon-circle__inner-icon"}/>
                            </div>
                            <Paragraph>
                                Takk for din henvendelse, <StrongText>{sender.navn}</StrongText>!
                            </Paragraph>
                            <Paragraph>
                                Vi vil ringe deg så snart som mulig.
                            </Paragraph>
                        </div>
                    }
                </GridCol>
            </GridRow>
        </Grid>
    );
};
export default BliOppringt;
