import React, { createRef, useEffect, useState, useRef } from 'react';
import classnames from 'classnames';

import { Grid, GridCol, GridRow } from '@sb1/ffe-grid-react';
import { Paragraph, SmallText } from '@sb1/ffe-core-react';

import VisPris from '~/components/produkt/VisPris';
import { Pris } from '~/components/tilbud/TilbudService';

export interface PrisbannerProps {
    pris: Pris;
    beregner: boolean;
}

const Prisbanner: React.FunctionComponent<PrisbannerProps> = ({ pris, beregner }) => {
    const innerRef = createRef<HTMLDivElement>();
    const [sticky, setSticky] = useState<boolean>(false);
    const bannerScrollPos = useRef<number>(-1);

    useEffect(() => {
        if (innerRef?.current) {
            bannerScrollPos.current = innerRef.current.offsetTop;
            window.scrollTo({
                top: innerRef.current.offsetTop,
                behavior: 'smooth'
            });
        }
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    function handleScroll(event) {
        event.preventDefault();
        const currentScrollPos = document.documentElement.scrollTop;
        const diff = currentScrollPos - bannerScrollPos.current;
        if (diff >= 1) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    }

    return (
        <>
            <div className={classnames('ksmb-produktspesifikasjon-grid--bg-blue-cobalt',
                { 'ksmb-produktspesifikasjon-prisbanner--sticky': sticky })}
            ref={innerRef}>
                <Grid>
                    <GridRow className={classnames('ksmb-produktspesifikasjon-grid__row--bg-blue-cobalt',
                        { 'ksmb-produktspesifikasjon-grid__row--sticky': sticky, 'ksmb--padding-top': !sticky })}>
                        <GridCol lg={{ offset: 3, cols: 7 }}>
                            <Paragraph className={classnames({ 'ksmb-body-paragraph--sm-margin-bottom': sticky })}>
                                <SmallText className="ksmb-text--white ksmb-text--uppercase">
                                    Totalt for bedriften
                                </SmallText>
                            </Paragraph>
                            <VisPris
                                belop={pris.pris}
                                beregner={beregner}
                                hvitTekst={true} />
                        </GridCol>
                    </GridRow>
                </Grid>
            </div>
            <div className={classnames({ 'ksmb-produktspesifikasjon-prisbanner-sticky-fill': sticky })} />
        </>
    );
};

export default Prisbanner;
