import {useContext} from "react";
import {Org, orgSjekk} from "~/components/steg1/OrgSokService";
import {initTilbud} from "~/components/steg1/Steg1";
import {useSettings} from "~/components/felles/Settings";
import {TilbudContext} from "~/components/tilbud/TilbudContext";
import {useErrorHandler} from "~/components/felles/ErrorHandler";
import {setLogOrgnr} from "~/utils/fetch";
import {atom, useRecoilState} from "recoil";

interface State {
    org?: Org;
    request?: string;
}
const orgnrAtom = atom<State>({
    key: 'org',
    default: {}
})

const useOrgnr = (orgnr?: string) => {
    const [ state, setState ] = useRecoilState(orgnrAtom);
    const { omkostningsmodell } = useSettings();
    const tilbudContext = useContext(TilbudContext);
    const { catchError } = useErrorHandler();
    const { org } = state;

    setLogOrgnr(orgnr);

    if (orgnr && orgnr !== state.request) {
        setState({request: orgnr})
        orgSjekk(orgnr).then((orgRespons: Org) => {
            setState({request: orgnr, org: orgRespons});
            tilbudContext.setTilbud(initTilbud(orgRespons, omkostningsmodell));
        }).catch(error => {
            catchError(error);
        });
    }

    if (orgnr === org?.orgnr) {
        return {org}
    }
    return {}
}
export default useOrgnr;