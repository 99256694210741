import React from 'react';
import {Heading2, Heading3, Paragraph, SmallText} from '@sb1/ffe-core-react';
import {Grid, GridCol, GridRow} from '@sb1/ffe-grid-react';
import {Tilbud} from '~/components/tilbud/Tilbud';
import HakeIkon from "@sb1/ffe-icons/icons/open/500/xl/done.svg";
import {Icon} from "@sb1/ffe-icons-react";
import {formaterBelop} from "~/utils/uiUtils";

export interface Params {
    tilbud: Tilbud;
}

const sparing = (tilbud: Tilbud) => {
    return `${tilbud.lavSats}% sparing, betalingsfritak`;
};

const ufore = (tilbud: Tilbud) => {
    return tilbud.uforeDekning ? ', uførepensjon' : '';
};

const tilleggssparing = (tilbud: Tilbud) => {
    return tilbud.hoySats > 0 ? `, ${tilbud.hoySats}% tilleggssparing ved lønn over 7,1G` : '';
};

const oppsummering = (tilbud: Tilbud) => {
    return `${sparing(tilbud)}${ufore(tilbud)}${tilleggssparing(tilbud)}`;
};

const Produkter: React.FunctionComponent<Params> = ({tilbud}) => {
    const pris = tilbud.pris ? formaterBelop(tilbud.pris) : 0;
    return (
        <Grid className={'ksmb-grid--margin-bottom'}>
            <GridRow>
                <GridCol lg={{ cols: 8, offset: 2 }}>
                    <Heading2>Produktene</Heading2>
                </GridCol>
            </GridRow>
            <GridRow className="ksmb-grid-row--border-bottom">
                <GridCol lg={{ cols: 8, offset: 2 }} className="ksmb-grid-col--half-padding-bottom">
                    <SmallText>Produkt</SmallText>
                </GridCol>
            </GridRow>
            <GridRow className="ksmb-grid-row--border-bottom" padding={'xl'}>
                <GridCol lg={{ cols: 7, offset: 2 }} sm={12}>
                    <Heading3>Innskuddspensjon</Heading3>
                    <Paragraph>{oppsummering(tilbud)}</Paragraph>
                    {tilbud.flyttesFraSelskap ?
                        <>
                            <Paragraph>Startdato for sparing: {tilbud.startdato}</Paragraph>
                            <Paragraph>
                                Avtalen {tilbud.flyttesFraAvtale && `med nr ${tilbud.flyttesFraAvtale} `}
                                blir flyttet fra {tilbud.flyttesFraSelskap}
                            </Paragraph>
                        </>
                        :
                        <Paragraph>Avtalen gjelder fra {tilbud.startdato}</Paragraph>
                    }
                </GridCol>
                <GridCol lg={2} sm={12} className="ksmb-grid-col--right-align">
                    <span>
                        <Heading3>{pris}</Heading3>
                        <span>per år</span>
                    </span>
                </GridCol>
                <GridCol lg={1} center={true}>
                    <Icon fileUrl={HakeIkon} size={"xl"} className={"ksmb-produkter-hake-ikon"}/>
                </GridCol>
            </GridRow>
            <GridRow background={'sand'} padding={'xl'}>
                <GridCol lg={{cols: 3, offset: 6}} sm={12}>
                    <Heading3>Totalt for bedriften</Heading3>
                </GridCol>
                <GridCol lg={2} sm={12} className="ksmb-grid-col--right-align">
                    <span>
                        <Heading3>{pris}</Heading3>
                        <span>per år</span>
                    </span>
                </GridCol>
            </GridRow>
        </Grid>
    );
};
export default Produkter;
