import {post} from '~/utils/fetch';
import { Tilbud } from '~/components/tilbud/Tilbud';

export interface BliOppringtRequest {
    navn: string;
    telefon: string;
    aarsak?: string | null;
    tilbud?: Tilbud;
}

export async function sendBliOppringt(request: BliOppringtRequest) {
    const response = await post('/api/blioppringt', request);
    return response;
}
