
const SSN_FACTORS_1 = [3, 7, 6, 1, 8, 9, 4, 5, 2];
const SSN_FACTORS_2 = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];

export const fjernMellomrom = fnr => fnr.replaceAll(' ', '');

const erGyldigFnr = fnr => {
    let i;
    let blnResult = true;
    // 1st checksum calculation using SSN_FACTORS_1 as weights
    let intCheckSum = 0;
    for (i = 0; i <= 8; ++i) {
        intCheckSum += (fnr.charAt(i) * SSN_FACTORS_1[i]);
    }
    blnResult = blnResult && (11 - (((intCheckSum - 1) % 11) + 1)).toString() === fnr.charAt(9); // tshint ignore:line
    // 2nd checksum calculation using SSN_FACTORS_2 as weights
    intCheckSum = 0;
    for (i = 0; i <= 9; ++i) {
        intCheckSum += (fnr.charAt(i) * SSN_FACTORS_2[i]);
    }
    blnResult = blnResult && ((11 - (((intCheckSum - 1) % 11) + 1)).toString() === fnr.charAt(10)); // jshint ignore:line
    return blnResult;
};

export const gyldigeFodselsnumre = fnr => {
    return !!(fnr && fnr.length === 11 && erGyldigFnr(fnr));
};
