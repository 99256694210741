import {get} from '~/utils/fetch';

export interface Org {
    orgnr: string;
    navn: string;
    poststed: string;
    enkeltpersonsforetak?: boolean;
}

export async function orgSok(sok: string) {
    const response = await get('/api/org/sok', {sok});
    return response as Org[];
}

export async function orgSjekk(orgnr: string) {
    const response = await get(`/api/org/sjekk/${orgnr}`);
    return response as Org;
}
