import React, { useCallback, useEffect, useRef, useState } from 'react';

const DEFAULT_MAX_DOTS = 5;

interface Props {
    /** A function returning a React element that accepts `children` as its props */
    wrapper: (children: string) => React.ReactElement;
    /** The maximum number of dots shown in the loading indicator */
    maxDots: number;
}

const LoadingText: React.FunctionComponent<Props> = ({maxDots, wrapper}) => {
    const [dots, setDots] = useState(0);
    const unmounted = useRef(false);

    const nextFrame = useCallback(() => {
        setDots(dots => (dots + 1) % maxDots);
        if (!unmounted.current) {
            setTimeout(() => {
                nextFrame();
            }, 500)
        }
    }, [maxDots]);

    useEffect(() => {
        nextFrame();
        return () => {
            unmounted.current = true;
        }
    }, [nextFrame]);

    const loaderText =
        new Array(dots + 1)
            .fill('.')
            .concat((new Array(maxDots - dots)).fill('\u00AD'))
            .join('');

    if (wrapper) {
        /*
                Need to allow consumer to hand a wrapper in case the text is used
                in an SVG where <span> won't work. The ideal thing would be to return
                plain text but https://github.com/facebook/react/issues/7353
                (Support for that is coming but not for a while)
            */
        return wrapper(loaderText);
    }

    return <span>{loaderText}</span>;
}

export default function loadingText(text, showLoader, wrapper, maxDots = DEFAULT_MAX_DOTS) {
    if (showLoader) {
        return (
            <LoadingText
                wrapper={wrapper}
                maxDots={maxDots}
            />
        );
    }
    return wrapper ? wrapper(text) : text;
}
