import {useEffect} from 'react';
import {Skjemadata, trackFormInitiated, trackFormStepStarted} from '@sparebank1/pensjon-bm-analytics';
import {setLogContext} from '~/utils/fetch';

export const useTrackPage = (skjema: Skjemadata, steg, onDestroy?) => {
    useEffect(() => {
        setLogContext(skjema.navn);
        trackFormInitiated(skjema);
        trackFormStepStarted(steg);
        return onDestroy;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
