import React, {useContext, useEffect, useState} from 'react';
import {Heading1, Paragraph} from '@sb1/ffe-core-react';
import {Grid, GridCol, GridRow} from '@sb1/ffe-grid-react';
import OrgSok from '~/components/steg1/OrgSok';
import {Org, orgSok} from '~/components/steg1/OrgSokService';
import OrgListe from '~/components/steg1/OrgListe';
import {useErrorHandler} from '~/components/felles/ErrorHandler';
import { Spinner } from '@sb1/ffe-spinner-react';
import {FormSubmitStatus, Skjemadata, trackPage} from '@sparebank1/pensjon-bm-analytics';
import {FormContext} from '~/Routing';
import useSkjemafeil from '~/components/tracking/useSkjemafeil';
import {Tilbud} from '~/components/tilbud/Tilbud';
import {finnInitiellePaakrevdeVerdier} from '~/components/tilbud/regler';
import useOrgnr from "~/components/ansatte/useOrgnr";
import {useNavigate} from "react-router";

export const PAGE = 'FORSIDEN: Orgsøk og antall ansatte';
export const SKJEMA: Skjemadata = {navn: 'Kjøp pensjon SMB', type: '', skjematype: 'KSMB'};
export const steg = {
    SOK_BEDRIFT: {navn: 'Søk bedrift', nummer: 1},
    VELG_BEDRIFT: {navn: 'Velg bedrift', nummer: 2},
    ANTALL_ANSATTE: {navn: 'Antall ansatte', nummer: 3}
};

export const initTilbud = (org: Org, omkostningsmodell: string): Tilbud => {
    const nyttTilbud: Tilbud = {
        orgnr: org.orgnr,
        orgnavn: org.navn,
        ansatte: [],
        forbehold: [],
        epost: '',
        status: 'DRAFT',
        uforeDekning: null,
        ...finnInitiellePaakrevdeVerdier(),
        omkostningsmodell
    };
    return nyttTilbud;
};

const Steg1: React.FunctionComponent = () => {

    const [result, setResult] = useState<Org[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const errorHandler = useErrorHandler();
    const formContext = useContext(FormContext);
    const [feilmelding, setFeilmelding] = useSkjemafeil('orgSok');
    const [orgnr, setOrgnr] = useState<string>();
    const { org } = useOrgnr(orgnr);
    const navigate = useNavigate();

    useEffect(() => {
        trackPage(PAGE);
    }, []);

    useEffect(() => {
        if (org) {
            navigate(`/org/${org.orgnr}`)
        }
    }, [org, navigate])

    function executeSok(query: string) {
        setOrgnr(undefined);
        if (query) {
            setFeilmelding('');
            errorHandler.setFeilmelding(null);
            setLoading(true);
            orgSok(query).then((resultRespons: Org[]) => {
                setLoading(false);
                setResult(resultRespons);
                if (resultRespons.length === 1) {
                    setOrgnr(resultRespons[0].orgnr);
                } else if (resultRespons.length === 0) {
                    setFeilmelding('Fant ingen bedrifter med dette navnet. Skriv inn bedriftsnavn eller organisasjonsnummer.');
                }
            }).catch(error => {
                setLoading(false);
                setResult([]);
                if (error.status !== 404 && error.details.melding) {
                    setFeilmelding(error.details.melding);
                } else {
                    formContext.setFormStatus(FormSubmitStatus.ERROR);
                    errorHandler.catchError(error);
                }
            });
        } else {
            setFeilmelding('Oppgi organisasjonsnummer eller bedriftsnavn');
        }
    }

    function nyttSok() {
        setResult([]);
        setOrgnr(undefined);
    }

    return <Grid className="ksmb-steg-1">
        <GridRow>
            <GridCol center={true} className='ksmb-grid-col--padding-bottom'>
                <Heading1 className="ksmb-header--center">Innskuddspensjon</Heading1>
            </GridCol>
        </GridRow>
        <GridRow>
            <GridCol lg={{offset: 3, cols: 6}} className='ksmb-grid-col--padding-bottom'>
                <Paragraph lead={true} className="ffe-body-paragraph--text-center">
                    Her kan du enkelt beregne pris for å etablere eller flytte bedriftens innskuddspensjon til oss.
                </Paragraph>
            </GridCol>
        </GridRow>
        {orgnr && (!org || org.orgnr !== orgnr) && !errorHandler.feilmelding &&
            <GridRow>
                <GridCol center={true}>
                    <div>
                        <Spinner large={true} className="ksmb-spinner--lg" loadingText="Henter informasjon om bedriften..." />
                    </div>
                </GridCol>
            </GridRow>
        }
        {(!orgnr && result.length <= 1 || errorHandler.feilmelding) &&
            <OrgSok feilmelding={feilmelding} onClick={executeSok} isLoading={loading}/>
        }
        {!orgnr && result.length > 1 && !errorHandler.feilmelding &&
            <OrgListe data={result} nyttSok={nyttSok} setOrgnr={setOrgnr}/>
        }
    </Grid>
};

export default Steg1;
