import { post } from "~/utils/fetch";
import { z } from "zod";
import { zString } from "~/components/validering/validering";

export const isEpost = (required: boolean) => async (value: string, ctx: z.RefinementCtx) => {
    if ((required || !!value?.length) && (!z.string().safeParse(value).success || !z.string().email().safeParse(value).success || !await post('/api/validering/epost', {epost: value}))) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Vennligst oppgi en gyldig e-postadresse'
        })
    }
}

const epost = (required: boolean) => (
    zString(required ? 'Vennligst oppgi en gyldig e-postadresse' : undefined)
        .superRefine(isEpost(required))
)

export default epost;
