import React from 'react';
import {ErrorFieldMessage} from '@sb1/ffe-form-react';

interface Props {
    forbehold: string[];
}

export const Forbehold: React.FunctionComponent<Props> = ({forbehold}) => {
    if (!forbehold) {
        return null;
    }
    return <>
        {forbehold.map((tekst, key) =>
            <ErrorFieldMessage key={key}>
                {tekst}
            </ErrorFieldMessage>
        )}
    </>
};
