import React, {useState} from 'react';
import {Grid, GridCol, GridRow} from '@sb1/ffe-grid-react';
import {Heading1, Heading2, LinkText, Paragraph} from '@sb1/ffe-core-react';
import {trackClick, trackFormStepStarted} from '@sparebank1/pensjon-bm-analytics';
import Ansatteskjema from '~/components/ansatte/Ansatteskjema';
import {Ansatt, Tilbud} from '~/components/tilbud/Tilbud';
import {dsfSjekk} from '~/components/ansatte/DsfService';
import {useInnloggetBruker} from '~/components/felles/InnloggetBruker';
import {useErrorHandler} from '~/components/felles/ErrorHandler';
import {Link} from "react-router-dom";
import {tilFlytting} from "~/Routing";
import {erOtpPliktig} from "~/components/tilbud/regler";
import Illustrasjon from '~/images/Ansattinformasjon.svg';
import {MessageBox} from "@sb1/ffe-messages-react";

const personvern = 'https://www.sparebank1.no/nb/bank/om-oss/personvern.html';

interface Props {
    tilbud: Tilbud;
    steg: object;
    knappetekst: string;
    onFinish: () => Promise<void>;
    beregner: boolean;
    setBeregner: (boolean) => void;
    flytting: boolean;
}

const Ansattinformasjon: React.FunctionComponent<Props> = ({ tilbud, knappetekst, steg, onFinish, beregner, setBeregner, flytting }) => {

    const { innlogget } = useInnloggetBruker();
    const [ dsfFeil, setDsfFeil ] = useState<boolean[]>(innlogget ? tilbud.ansatte.map(ansatt => ansatt.dsfOk === false) : []);
    const { catchError } = useErrorHandler();

    async function sjekkDsf(ansatte: Ansatt[]) {
        setBeregner(true);
        tilbud.otpPliktig = erOtpPliktig(ansatte);
        if (innlogget) {
            try {
                trackFormStepStarted(steg);
                tilbud.ansatte = await dsfSjekk(ansatte);
                if (tilbud.ansatte.every(ansatt => ansatt.dsfOk)) {
                    return onFinish();
                } else {
                    setBeregner(false);
                    setDsfFeil(tilbud.ansatte.map(ansatt => !ansatt.dsfOk));
                }
            } catch(error: any) {
                setBeregner(false);
                catchError(error);
            }
        } else {
            tilbud.ansatte = ansatte;
            return onFinish();
        }
    }
    return <Grid>
        <GridRow className={'ksmb--padding-top'}>
            <GridCol lg={{ offset: 2, cols: 8 }} center={true} className='ksmb-grid-col--padding-bottom'>
                <Heading1 textCenter={true}>Ansattinformasjon</Heading1>
            </GridCol>
            <GridCol center={true} className='ksmb-grid-col--padding-bottom'>
                <img src={Illustrasjon} alt="" />
            </GridCol>
            {flytting ?
                <GridCol center={true} className='ksmb-grid-col--padding-bottom'>
                    <MessageBox type={"info"} header="Har du ikke ansattopplysninger tilgjengelig?">
                        <Paragraph>
                            Du kan flytte bedriftens pensjonsavtale til oss uten ansattopplysninger.
                            Vi har gode konkurransebetingelser, og våre kunder er blant de som har fått mest igjen for
                            pensjonspengene sine i våre aktivt forvaltede spareprofiler.
                            Hvis du flytter uten ansattopplysninger kan vi ikke beregne pris før avtalen er inngått.
                        </Paragraph>
                        <Link to={tilFlytting(tilbud.orgnr, tilbud.ansatte.length)}>Flytt pensjonsavtalen til SpareBank 1</Link>
                    </MessageBox>
                </GridCol>
                : null
            }
        </GridRow>
        <GridRow>
            <GridCol className='ksmb-grid-col--padding-bottom'>
                <Heading2 lookLike={3}>Ansattopplysninger</Heading2>
                Vi behandler personopplysninger etter personopplysningsloven og sletter derfor alle opplysninger om du velger å ikke bli kunde hos oss.
                <div>
                    <LinkText
                        href={personvern}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => trackClick('ansattinformasjon Les mer - personvern')}
                    >
                        Les mer
                    </LinkText>.
                </div>
            </GridCol>
        </GridRow>
        <GridRow>
            <GridCol center={false} className='ksmb-grid-col--padding-bottom'>
                <Ansatteskjema ansatte={tilbud.ansatte}
                    beregner={beregner}
                    knappetekst={knappetekst}
                    utforSjekkPris={sjekkDsf}
                    dsfFeil={dsfFeil}/>
            </GridCol>
        </GridRow>
    </Grid>
}
export default Ansattinformasjon;
