import React from 'react';
import classnames from 'classnames';
import {Grid, GridCol, GridRow} from '@sb1/ffe-grid-react';
import {Heading2, Paragraph, SmallText} from '@sb1/ffe-core-react';
import {useSettings} from '~/components/felles/Settings';
import {apningstiderTekst, erInnenforApningstidene} from '~/components/felles/apningstiderService';
import {TertiaryButton} from '@sb1/ffe-buttons-react';
import TrackedButtons from '~/components/tracking/TrackedButtons';
import { Icon } from "@sb1/ffe-icons-react";
import ChevronIkon from "@sb1/ffe-icons/icons/open/500/xl/expand_more.svg";
import PersonMikrofonIkon from "@sb1/ffe-icons/icons/open/500/xl/support_agent.svg";
import SnakkebobleIkon from "@sb1/ffe-icons/icons/open/500/xl/chat_bubble.svg";
import TelefonIkon from "@sb1/ffe-icons/icons/open/500/xl/phone_enabled.svg";


export interface TrengerDuHjelpProps {
    kontaktskjemaApent: boolean;
    toggleKontaktskjema: () => void;
    toggleChat: () => void;
}

const TrengerDuHjelp: React.FunctionComponent<TrengerDuHjelpProps> = (
    { kontaktskjemaApent, toggleKontaktskjema, toggleChat }
) => {
    const settings = useSettings();
    const apent = erInnenforApningstidene(settings);

    return <section className="ksmb-kontakt">
        <Grid className='ksmb--padding-top'>
            <GridRow>
                <GridCol center={true} className='ksmb-grid-col--padding-bottom'>
                    <Heading2>Trenger du hjelp?</Heading2>
                </GridCol>
            </GridRow>
            <GridRow>
                <GridCol center={true} className='ksmb--flex-column'>
                    <div className="ksmb-kontakt-metoder">
                        <div className="ksmb-kontakt-metode">
                            <div className={
                                classnames('ksmb-icon-circle ksmb-icon-circle--large ksmb-kontakt-metode-icon',
                                    {
                                        'ksmb-icon-circle--grey-charcoal--inactive ksmb-icon-circle--no-outline':
                                                !apent
                                    })}>
                                <Icon fileUrl={TelefonIkon} size={"xl"} className="ksmb-icon-circle__inner-icon" ariaLabel={apent ? "åpen" : "stengt"} />
                            </div>
                            <Paragraph className="ksmb-kontakt-paragraph">
                                Ring {settings.kontakt_telefon}
                            </Paragraph>
                            <SmallText>
                                {apningstiderTekst(settings)}
                            </SmallText>
                        </div>
                        <div className="ksmb-kontakt-metode">
                            <TrackedButtons knappNavn="Trenger du hjelp ikon: Bli oppringt">
                                <div role="none" className={
                                    classnames('ksmb-icon-circle ksmb-icon-circle--large ksmb-kontakt-metode-icon ksmb-icon--pointer', { 'ksmb-icon-circle--blue-royal': kontaktskjemaApent })}
                                onClick={() => toggleKontaktskjema()}>
                                    <Icon fileUrl={PersonMikrofonIkon} size={"xl"} className="ksmb-icon-circle__inner-icon" ariaLabel="bli oppringt" />
                                </div>
                            </TrackedButtons>
                            <TrackedButtons knappNavn="Trenger du hjelp: Bli oppringt">
                                <TertiaryButton type="button"
                                    aria-controls="kontaktskjema"
                                    aria-expanded={kontaktskjemaApent}
                                    className="ksmb-button-link"
                                    onClick={() => toggleKontaktskjema()}
                                    data-testid="bli_oppringt">
                                        Bli oppringt
                                </TertiaryButton>
                            </TrackedButtons>
                            <SmallText>
                                Oppgi kontaktinfo
                            </SmallText>
                        </div>
                        <div className="ksmb-kontakt-metode">
                            <TrackedButtons knappNavn="Trenger du hjelp ikon: Chat">
                                <div role="button" tabIndex={-1} className={
                                    // tslint:disable-next-line: max-line-length
                                    classnames('ksmb-icon-circle ksmb-icon-circle--large ksmb-kontakt-metode-icon ksmb-icon--pointer',
                                        {
                                            'ksmb-icon-circle--grey-charcoal--inactive ksmb-icon-circle--no-outline':
                                                    !apent
                                        })}
                                onClick={() => toggleChat()} onKeyPress={() => {}}>
                                    <Icon fileUrl={SnakkebobleIkon} size={"xl"} className="ksmb-icon-circle__inner-icon" ariaLabel="chat med oss" />
                                </div>
                            </TrackedButtons>
                            {apent &&
                                <TrackedButtons knappNavn="Trenger du hjelp: Chat">
                                    <TertiaryButton type="button"
                                        aria-controls="chat"
                                        className="ksmb-button-link"
                                        onClick={() => toggleChat()}
                                    >
                                        Chat
                                    </TertiaryButton>
                                </TrackedButtons>
                            }
                            {!apent &&
                                <Paragraph className="ksmb-kontakt-paragraph">
                                    Chat stengt
                                </Paragraph>
                            }
                            <SmallText>
                                {apningstiderTekst(settings)}
                            </SmallText>
                        </div>
                    </div>
                    <div className={classnames('ksmb-icon-circle ksmb-icon-circle--white ksmb-icon-circle--chevron',
                        { 'ksmb-icon-circle--hidden': !kontaktskjemaApent })}
                    hidden={!kontaktskjemaApent}
                    role="presentation" data-testid="chevron">
                        <Icon fileUrl={ChevronIkon} size={"xl"} className="ksmb-icon-circle__inner-icon" />
                    </div>
                </GridCol>
            </GridRow>
        </Grid>
    </section>
};
export default TrengerDuHjelp;
