import React, {PropsWithChildren} from 'react';

interface Props {
    handleException: (error) => void;
}

class ErrorBoundary extends React.Component<PropsWithChildren<Props>> {

    constructor(props: Props) {
        super(props);
    }

    // Denne kalles ved js-feil (exceptions)
    componentDidCatch(error: Error) {
        this.props.handleException(error);
    }

    render() {
        return this.props.children;
    }
}

export default ErrorBoundary;
