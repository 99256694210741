export interface Ansatt {
    fnr: string;
    fornavn: string;
    etternavn: string;
    lonn: number | null;
    stillingspros: number;
    ansattdato: string;
    eier: boolean | null;
    arbeidsdyktig: boolean | null;
    dsfOk: boolean | null;
}

export const ansattMedDefaultVerdier: Ansatt = {
    fnr: '',
    fornavn: '',
    etternavn: '',
    lonn: null,
    stillingspros: 100,
    ansattdato: '',
    eier: null,
    arbeidsdyktig: null,
    dsfOk: null
};

export interface Tilbud {
    orgnr: string;
    orgnavn: string;
    omkostningsmodell: string;
    otpPliktig?: boolean;
    uforeDekning: boolean | null;
    lavSats: number;
    hoySats: number;
    ansatte: Ansatt[];
    pris?: number;
    forbehold: string[];
    epost: string;
    status: string;
    startdato: string;
    tilbudsdato?: string;
    flyttesFraSelskap?: string;
    flyttesFraAvtale?: string;
}
