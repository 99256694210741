import {add, format, parse, set} from "date-fns";

export function forsteINesteManed(): Date {
    return set(add(new Date(), { months: 1 }), { date: 1 });
}

export function flyttedato(): string {
    return formatDate(add(forsteINesteManed(), { months: 2 }))
}

export function formatDate(d: Date): string {
    return format(d, 'dd.MM.yyyy');
}

export function parseDate(s: string): Date {
    return parse(s, 'dd.MM.yyyy', new Date());
}
