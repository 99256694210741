import React from 'react';
import {get} from '~/utils/fetch';
import {useInnloggetBruker} from '~/components/felles/InnloggetBruker';
import {SecondaryButton} from '@sb1/ffe-buttons-react';
import {useNavigate} from 'react-router';
import {trackClick} from '@sparebank1/pensjon-bm-analytics';
import Logo from '~/images/Sparebank1-logo.svg';

const Header: React.FunctionComponent = () => {
    const { innlogget, fornavn, etternavn, refresh } = useInnloggetBruker();
    const navigate = useNavigate();

    const logout = () => {
        get('/api/logout').then(() => {
            refresh();
            navigate('/utlogget');
        });
    };
    return (
        <header className="ffe-header ffe-header__border">
            <div className="ffe-header__wrapper ksmb-header__wrapper">
                <div className="ffe-header__logo ksmb-header__logo--left">
                    <a href="https://www.sparebank1.no/nb/bank/bedrift.html"
                        onClick={() => trackClick('header: SpareBank 1 logo')}
                        aria-label={'Portalen'}
                        rel="noopener noreferrer"
                        className="ksmb-header__brand-link">
                        <img src={Logo} className="ffe-header__logo-svg" alt="SpareBank 1 logo" />
                    </a>
                </div>
                {innlogget &&
                    <div className="ksmb-header__logout-wrapper">
                        <div>
                            {fornavn} {etternavn}
                        </div>
                        <SecondaryButton onClick={() => logout()}>
                            Logg ut
                        </SecondaryButton>
                    </div>
                }
            </div>
        </header>
    );
};

export default Header;
