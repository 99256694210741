import React from 'react';
import classnames from 'classnames';
import {Paragraph, SmallText} from '@sb1/ffe-core-react';
import {formaterBelop} from "~/utils/uiUtils";

export interface VisPrisProps {
    belop: number;
    beregner?: boolean;
    hvitTekst?: boolean;
    noMargin?: boolean;
    className?: string;
}

const VisPris: React.FunctionComponent<VisPrisProps> = ({ belop, beregner, hvitTekst, noMargin, className }) => {
    return (
        <Paragraph noMargin={noMargin} className={
            classnames(
                {
                    'ksmb-text--white':
                        hvitTekst
                }, className)}>
            <span className={
                classnames('ksmb-pris__spinner',
                    { 'ksmb-pris__spinner--white': hvitTekst },
                    { 'ksmb-transitions-fade-out': !beregner },
                    { 'ksmb-transitions-fade-in': beregner }
                )} />
            <span className={
                classnames('ffe-h3 ksmb-pris__sum',
                    { 'ksmb-transitions-fade-out': beregner },
                    { 'ksmb-transitions-fade-in': !beregner },
                    { 'ksmb-text--white': hvitTekst })}>
                {formaterBelop(belop)}</span>
            <SmallText className={
                classnames(
                    {
                        'ksmb-text--white':
                            hvitTekst
                    })}> per år</SmallText>
        </Paragraph>
    );
};

export default VisPris;
