import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Input, InputGroup } from '@sb1/ffe-form-react';
import { ButtonGroup, PrimaryButton } from '@sb1/ffe-buttons-react';
import { gyldigeNavn, gyldigeTelefonnumre } from '~/components/validering/regler';
import { Heading2, Paragraph } from '@sb1/ffe-core-react';
import { apningstiderTekstOppringning } from '~/components/felles/apningstiderService';
import { SettingsType } from '~/components/felles/Settings';
import TrackedButtons from '~/components/tracking/TrackedButtons';
import { z } from "zod";
import { zString } from "~/components/validering/validering";
import { zodResolver } from "@hookform/resolvers/zod";

export interface KontaktMegInput {
    navn: string;
    telefon: string;
}

export interface Props {
    feilmelding?: string | null;
    settings: SettingsType;
    utforSendBliOppringt: (values: KontaktMegInput) => void;
    setErSkjemafeil: (erSkjemafeil: boolean) => void;
    sender: boolean;
    className?: string;
}

const valideringPerson = z.object({
    navn: zString('Du må fylle ut navn')
        .trim()
        .regex(gyldigeNavn, 'Ugyldig navn'),
    telefon: zString('Du må fylle ut telefonnummer')
        .trim()
        .regex(gyldigeTelefonnumre, 'Ugyldig telefonnummer')
})
const Kontaktskjema: React.FunctionComponent<Props> =
    ({
        feilmelding,
        settings,
        utforSendBliOppringt,
        setErSkjemafeil,
        sender,
        className
    }) => {

        const initiellKontaktMegInput: KontaktMegInput = {
            navn: '',
            telefon: ''
        };

        const { register, handleSubmit, formState: { errors } } = useForm<KontaktMegInput>({
            resolver: zodResolver(valideringPerson),
            defaultValues: initiellKontaktMegInput
        });

        useEffect(() => {
            setErSkjemafeil(!!errors);
        }, [errors, setErSkjemafeil]);

        return (
            <>
                <Heading2 lookLike={3}>Hvem skal vi kontakte?</Heading2>
                {feilmelding &&
                    <Paragraph>{feilmelding}</Paragraph>
                }
                <Paragraph>{apningstiderTekstOppringning(settings)}</Paragraph>
                <form className={className} onSubmit={handleSubmit(utforSendBliOppringt)}>
                    <InputGroup label="Navn"
                        fieldMessage={errors?.navn?.message}>
                        <Input {...register('navn')}
                            className="ksmb-input-field ksmb-input-field--lg"
                            autoComplete="on"
                            autoFocus={true} />
                    </InputGroup>
                    <InputGroup label="Telefonnummer"
                        fieldMessage={errors?.telefon?.message}>
                        <Input {...register('telefon')}
                            className="ksmb-input-field ksmb-input-field--md"
                            autoComplete="on" />
                    </InputGroup>
                    <ButtonGroup ariaLabel="Knapp for sende inn kontaktskjema og bli oppringt av en rådgiver">
                        <TrackedButtons knappNavn="Kontaktskjema: Bli oppringt">
                            <PrimaryButton type="submit"
                                isLoading={sender}
                                ariaLoadingMessage="Vennligst vent"
                                data-testid="send_bli_oppringt">
                                Bli oppringt
                            </PrimaryButton>
                        </TrackedButtons>
                    </ButtonGroup>
                </form>
            </>
        );
    };

export default Kontaktskjema;
