import React, {useEffect, useState} from "react";
import classNames from "classnames";
import {ButtonGroup, PrimaryButton} from "@sb1/ffe-buttons-react";
import {Heading1} from "@sb1/ffe-core-react";
import {trackClick} from "@sparebank1/pensjon-bm-analytics";

const hendelse = 'applikasjonen-er-oppdatert';

let frontendVersjon;

export const setBackendVersjon = (versjon: string | null) => {
    if (versjon) {
        if (!frontendVersjon) {
            frontendVersjon = versjon; // Satser i første omgang på at det er riktig versjon første gangen
        } else if (frontendVersjon !== versjon) {
            window.dispatchEvent(new Event(hendelse))
        }
    }
}

const ApplikasjonenErOppdatert: React.FunctionComponent = () => {
    const [ applikasjonenErOppdatert, setApplikasjonenErOppdatert] = useState(false);
    const [ lasterSidenPaNytt, setLasterSidenPaNytt ] = useState(false);

    useEffect(() => {
        const visApplikasjonenErOppdatert = () => {
            setApplikasjonenErOppdatert(true)
        };
        window.addEventListener(hendelse, visApplikasjonenErOppdatert);
        return () => {
            window.removeEventListener(hendelse, visApplikasjonenErOppdatert);
        };
    }, []);

    const lastSidenPaNytt = () => {
        trackClick('oppdater.applikasjon')
        setLasterSidenPaNytt(true);
        document.location.reload();
    }

    return <div className={classNames('kfb-applikasjonen-er-oppdatert', { 'kfb-applikasjonen-er-oppdatert--vis': applikasjonenErOppdatert})}>
        { applikasjonenErOppdatert ? 
            <div role="alert" className={'kfb-applikasjonen-er-oppdatert-innhold'}>
                <div>
                    <Heading1 lookLike={5}>Applikasjonen er oppdatert</Heading1>
                    Vennligst last siden på nytt når du har mulighet.
                </div>
                <ButtonGroup thin={true} ariaLabel="Knapp for å laste siden på nytt">
                    <PrimaryButton
                        onClick={lastSidenPaNytt}
                        isLoading={lasterSidenPaNytt}
                    >
                        Oppdater
                    </PrimaryButton>
                </ButtonGroup>
            </div>
            : null }
    </div>
}
export default ApplikasjonenErOppdatert;