import isHoliday from 'holidays-nordic';
import {SettingsType} from '~/components/felles/Settings';
import {isAfter, isBefore, isWeekend, parse, sub} from 'date-fns';
import {fromZonedTime} from 'date-fns-tz';

const formatter = (s: string) => s.replace(':', '.');

const erHelg = (now: Date) => {
    return isWeekend(now);
};

const erHelligdag = (now: Date) => {
    return isHoliday(now, 'no');
};

const erSommertid = (now: Date) => {
    const sommertidStart = fromZonedTime(parse('15.05', 'dd.MM', new Date()), 'Europe/Oslo');
    const vintertidStart = fromZonedTime(parse('15.09', 'dd.MM', new Date()), 'Europe/Oslo');
    return isAfter(now, sub(sommertidStart, {seconds: 1})) && isBefore(now, vintertidStart);
}

export const erInnenforApningstidene = (settings: SettingsType): boolean => {
    const now = new Date();
    if (erHelg(now) || erHelligdag(now)) {
        return false;
    }
    const apnerTid: Date = fromZonedTime(parse(settings.kontakt_apner, 'HH:mm', new Date()), 'Europe/Oslo');
    const stengerTxt = erSommertid(now) ? settings.kontakt_stenger_sommer : settings.kontakt_stenger_vinter;
    const stengerTid = fromZonedTime(parse(stengerTxt, 'HH:mm', new Date()), 'Europe/Oslo');
    return isAfter(now, sub(apnerTid, {seconds: 1})) && isBefore(now, stengerTid);
};

export const apner = (settings: SettingsType): string => settings.kontakt_apner;

export const stenger = (settings: SettingsType): string => {
    const now = new Date();
    return erSommertid(now) ? settings.kontakt_stenger_sommer : settings.kontakt_stenger_vinter;
};

export const apningstiderTekst = (settings: SettingsType): string => {
    return `Hverdager ${formatter(settings.kontakt_apner)} - ${formatter(stenger(settings))}`;
};

export const apningstiderTekstOppringning = (settings: SettingsType): string => {
    const now = new Date();
    const stengerTxt = erSommertid(now) ? settings.kontakt_stenger_sommer : settings.kontakt_stenger_vinter;
    return `Du vil bli oppringt mellom kl ${formatter(settings.kontakt_apner)} og ${formatter(
        stengerTxt)} på hverdager.`;
};
