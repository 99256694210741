import React, {useRef} from 'react';
import {Input, InputGroup} from '@sb1/ffe-form-react';
import {ButtonGroup, PrimaryButton} from '@sb1/ffe-buttons-react';
import {GridCol, GridRow} from '@sb1/ffe-grid-react';
import {SKJEMA, steg} from '~/components/steg1/Steg1';
import TrackedButtons from '~/components/tracking/TrackedButtons';
import {useTrackPage} from '~/components/tracking/useTrackPage';

interface Props {
    feilmelding: string | undefined;
    onClick: (s: string) => void;
    isLoading: boolean;
}

const OrgSok: React.FunctionComponent<Props> = ({feilmelding, onClick, isLoading}) => {

    useTrackPage(SKJEMA, steg.SOK_BEDRIFT);

    const sok = useRef('');
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        sok.current = e.target.value;
    };
    const onSubmit = (e: React.FormEvent) => {
        onClick(sok.current);
        e.preventDefault();
    };

    return <GridRow>
        <GridCol lg={{ offset: 3, cols: 6 }} className='ksmb-grid-col--padding-bottom'>
            <form>
                <InputGroup
                    inputId="org_sok"
                    label="Bedriftens organisasjonsnummer"
                    fieldMessage={feilmelding && feilmelding.length > 0 ? feilmelding : undefined}
                >
                    <Input
                        className="ksmb-input-field ksmb-input-field--xl"
                        placeholder="Org.nr eller bedriftsnavn"
                        onChange={onChange}
                        autoFocus={true}
                    />
                </InputGroup>
                <ButtonGroup className="ksmb-button-group--md" ariaLabel="Knapp for å søke etter bedrift">
                    <TrackedButtons steg={steg.SOK_BEDRIFT.navn} knappNavn="sok">
                        <PrimaryButton type="submit" onClick={(e) => onSubmit(e)} isLoading={isLoading}>
                            Finn bedrift
                        </PrimaryButton>
                    </TrackedButtons>
                </ButtonGroup>
            </form>
        </GridCol>
    </GridRow>
};
export default OrgSok;
