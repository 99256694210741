import * as React from 'react';
import { GridRow, GridCol, Grid } from '@sb1/ffe-grid-react';
import { LinkText } from '@sb1/ffe-core-react';
import { trackClick } from '@sparebank1/pensjon-bm-analytics';

const personvern = 'https://www.sparebank1.no/nb/bank/om-oss/personvern.html';
const spb1forsikring = 'https://kundeforsikring.sparebank1.no/privat/forsikring/pensjon/selskapsinformasjon';

function Footer() {
    return (
        <footer>
            <Grid className="ksmb-footer">
                <GridRow>
                    <GridCol sm={12} center={true}>
                        <div className="ksmb-footer--center">
                            <span className="ksmb-footer-link">
                                <LinkText href={spb1forsikring}
                                    target="_blank" onClick={() => trackClick('bunntekst SpareBank1 Forsikring')}
                                    rel="noopener noreferrer">
                                    &#169; SpareBank 1 Forsikring
                                </LinkText>
                            </span>
                            <span className="ksmb-footer-link">
                                <LinkText href={personvern}
                                    target="_blank" onClick={() => trackClick('bunntekst Personvern og vilkår')}
                                    rel="noopener noreferrer">
                                    Personvern og vilk&#229;r for bruk av nettsiden
                                </LinkText>
                            </span>
                        </div>
                    </GridCol>
                </GridRow>
            </Grid>
        </footer>
    );
}

export default Footer;
