import React from 'react';
import {Heading2, Heading3, Paragraph, SmallText} from '@sb1/ffe-core-react';
import {Grid, GridCol, GridRow} from '@sb1/ffe-grid-react';
import {Link} from 'react-router-dom';
import {trackClick} from '@sparebank1/pensjon-bm-analytics';
import {Forbehold} from '~/components/produkt/Forbehold';
import useTilbud from '~/components/tilbud/useTilbud';
import {Tilbud} from "~/components/tilbud/Tilbud";
import {formaterBelop} from "~/utils/uiUtils";

const sparing = (tilbud) => {
    return `${tilbud.lavSats}% sparing, betalingsfritak`;
};

const ufore = (tilbud: Tilbud) => {
    return tilbud.uforeDekning ? ', uførepensjon' : '';
};

const tilleggssparing = (tilbud) => {
    return tilbud.hoySats > 0 ? `, ${tilbud.hoySats}% tilleggssparing ved lønn over 7,1G` : '';
};

const oppsummering = (tilbud) => {
    return `${sparing(tilbud)}${ufore(tilbud)}${tilleggssparing(tilbud)}`;
};

const Produkter: React.FunctionComponent = () => {
    const { tilbud } = useTilbud();
    const pris = tilbud.pris ? formaterBelop(tilbud.pris) : 0;
    return <Grid>
        <GridRow>
            <GridCol lg={{ cols: 8, offset: 2 }}>
                <Heading2>Produktene</Heading2>
            </GridCol>
        </GridRow>
        <GridRow className="ksmb-grid-row--border-bottom">
            <GridCol lg={{ cols: 8, offset: 2 }} className="ksmb-grid-col--half-padding-bottom">
                <SmallText>Produkt</SmallText>
            </GridCol>
        </GridRow>
        <GridRow className="ksmb-grid-row--border-bottom ksmb--padding-top">
            <GridCol lg={{ cols: 8, offset: 2 }} sm={12} className='ksmb-grid-col--padding-bottom'>
                <Heading3>Innskuddspensjon</Heading3>
                <Paragraph>{oppsummering(tilbud)}</Paragraph>
                <Forbehold forbehold={tilbud.forbehold} />
                <a href="https://www.sparebank1.no/content/dam/SB1/vedlegg/rapporter/Pensjon/vilkar-innskuddspensjon-med-individuelt-investeringsvalg.pdf" target="_blank"
                    rel="noopener noreferrer">ÅPNE VILKÅR I PDF</a>
            </GridCol>
            <GridCol lg={2} sm={12} className="ksmb-grid-col--right-align">
                <span>
                    <Heading3>{pris}</Heading3>
                    <span>per år</span>
                </span>
            </GridCol>
        </GridRow>
        <GridRow background={'sand'} className={'ksmb--padding-top'}>
            <GridCol lg={{ cols: 3, offset: 6 }} sm={12}>
                <Heading3>Totalt for bedriften</Heading3>
            </GridCol>
            <GridCol lg={2} sm={12} className="ksmb-grid-col--right-align ksmb-grid-col--padding-bottom">
                <span>
                    <Heading3>{pris}</Heading3>
                    <span>per år</span>
                </span>
            </GridCol>
        </GridRow>
        <GridRow background={'sand'}>
            <GridCol lg={{ cols: 4, offset: 7 }} className='ksmb-grid-col--padding-bottom ksmb--flex-column ksmb--flex-end'>
                <Link to={`./endreprodukt`} className="ffe-link-text ksmb-produkter-lenke-align-right"
                    onClick={() => trackClick('endre produktspesifikasjoner')}>
                    ENDRE PRODUKTSPESIFIKASJONER
                </Link>
            </GridCol>
        </GridRow>
    </Grid>
}
export default Produkter;
