import React, {useEffect, useState} from 'react';
import {Route, Routes} from 'react-router';
import EndreAnsattinformasjon from '~/components/ansatte/EndreAnsattinformasjon';
import Produktspesifikasjoner from '~/components/produkt/Produktspesifikasjoner';
import Signering from '~/components/signering/Signering';
import {Skjemadata, trackFormStepStarted} from '@sparebank1/pensjon-bm-analytics';
import {useTrackPage} from '~/components/tracking/useTrackPage';
import {harSigneringsrett} from '~/components/tilbud/TilbudService';
import useTilbud from '~/components/tilbud/useTilbud';
import {useErrorHandler} from '~/components/felles/ErrorHandler';
import AlleredeSignert from '~/components/signering/AlleredeSignert';
import { Spinner } from "@sb1/ffe-spinner-react";
import {Grid, GridCol, GridRow} from "@sb1/ffe-grid-react";

const SKJEMA: Skjemadata = { navn: 'Signering', type: '', skjematype: 'KSMB' };
const steg = {
    SIGNERINGSFLYT: { navn: 'Signeringsflyt', nummer: 1 },
    SJEKK_SIGNERINGSRETT: { navn: 'Sjekk om kunden har rett til å signere', nummer: 2 },
    MANGLER_SIGNERINGSRETT: { navn: 'Kunden mangler rett til å signere', nummer: 3 },
};

const SigneringRoutes: React.FunctionComponent = () => {

    const [ signeringsrettSjekket, setSigneringsrettSjekket ] = useState(false);
    const [ signeringsrettOk, setSigneringsrettOk ] = useState(false);
    const { tilbudId, tilbud } = useTilbud();
    const { catchError } = useErrorHandler();

    useTrackPage(SKJEMA, steg.SIGNERINGSFLYT);

    useEffect(() => {
        trackFormStepStarted(steg.SJEKK_SIGNERINGSRETT);
        harSigneringsrett(tilbudId).then(() => {
            setSigneringsrettOk(true);
        }).catch(e => {
            trackFormStepStarted(steg.MANGLER_SIGNERINGSRETT);
            catchError(e);
        }).finally(() => {
            setSigneringsrettSjekket(true)
        });
    }, [tilbudId]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!signeringsrettSjekket) {
        return <Grid>
            <GridRow>
                <GridCol center={true}>
                    <Spinner large={true} loadingText="Sjekker signeringsrett" />
                </GridCol>
            </GridRow>
        </Grid>
    }
    if (tilbud.status === 'ORDERSIGNED' || tilbud.status === 'SALE_REGISTERED') {
        return <AlleredeSignert />;
    }
    if (!signeringsrettOk) {
        return null;
    }

    return <Routes>
        <Route path={'/endreansatte'} element={<EndreAnsattinformasjon />} />
        <Route path={'/endreprodukt'} element={<Produktspesifikasjoner endreModus={true} />} />
        <Route path={'/'} element={<Signering />} />
    </Routes>
}
export default SigneringRoutes;
