import React from 'react';
import {Grid, GridCol, GridRow} from '@sb1/ffe-grid-react';
import {Heading1, LinkText} from '@sb1/ffe-core-react';
import {useSettings} from '~/components/felles/Settings';
import {Skjemadata} from '@sparebank1/pensjon-bm-analytics';
import {useTrackPage} from '~/components/tracking/useTrackPage';

const SKJEMA: Skjemadata = { navn: 'Signering', type: '', skjematype: 'KSMB' };
const steg = {
    ALLEREDE_SIGNERT: { navn: 'Tilbudet var allerede signert', nummer: 1 }
};

const AlleredeSignert: React.FunctionComponent = () => {
    useTrackPage(SKJEMA, steg.ALLEREDE_SIGNERT);
    const { kfb_url } = useSettings();
    return <Grid className="ksmb-grid--margin-bottom">
        <GridRow>
            <GridCol center={true}>
                <Heading1>Signering er fullført</Heading1>
            </GridCol>
            <GridCol center={true} background={'sand'}>
                <span>Tilbudet er allerede signert. Når avtalen er etablert vil du finne den i&nbsp;
                    <LinkText href={`${kfb_url}kfb/app/index.html`}>kundeportalen</LinkText>.
                </span>
            </GridCol>
        </GridRow>
    </Grid>
};
export default AlleredeSignert;
