import React, {PropsWithChildren, useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import Stacktrace from 'stacktrace-js';
import ErrorBoundary from '~/components/felles/ErrorBoundary';
import {post} from '~/utils/fetch';

const ErrorContext = React.createContext({
    feilmelding: null as string | null,
    setFeilmelding: (_: string | null) => {},
    catchError: (_error: Error) => {}
});

export const useErrorHandler = () => {
    return useContext(ErrorContext);
}

export interface Props {
    apneKontaktskjema: () => void;
    lukkKontaktskjema: () => void;
}

const ErrorHandler: React.FunctionComponent<PropsWithChildren<Props>> = ({apneKontaktskjema, lukkKontaktskjema, children}) => {
    const [feilmelding, setFeilmeldingInternal] = useState<string | null>(null);
    const location = useLocation();

    useEffect(() => {
        setFeilmelding(null);
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    const setFeilmelding = (nyFeilmelding: string | null) => {
        if (nyFeilmelding === null) {
            if (feilmelding !== null) {
                setFeilmeldingInternal(null);
                lukkKontaktskjema();
            }
        } else {
            if (nyFeilmelding !== feilmelding) {
                setFeilmeldingInternal(nyFeilmelding);
            }
            apneKontaktskjema();
        }
    }

    const handleException = error => {
        if (error.message) {
            setFeilmelding(error.message);
        }
        Stacktrace.fromError(error).then((mappedStack) => {
            return post('/api/logjs', {
                message: error.message,
                mappedStack: mappedStack.map(sf => sf.toString()).join('\n')
            });
        }).then(() => {}).catch(() => {});
    };

    const catchError = (error: any) => {
        if (error.details && error.details.melding) {
            setFeilmelding(error.details.melding);
        } else if (error.status === 403) {
            // tslint:disable-next-line: max-line-length
            setFeilmelding('Vi kan ikke bekrefte din rolle mot Brønnøysundregistrene. Fyll inn følgende informasjon så kontakter vi deg. Bestillingen er lagret og kan hentes opp når tilgang er gitt.');
        } else {
            // tslint:disable-next-line: max-line-length
            setFeilmelding('En uventet feil har oppstått. Prøv igjen senere eller legg igjen kontaktinformasjon under.');
        }
    }

    return (
        <ErrorContext.Provider value={{feilmelding, setFeilmelding, catchError}}>
            <ErrorBoundary handleException={handleException}>
                {children}
            </ErrorBoundary>
        </ErrorContext.Provider>
    );
};

export default ErrorHandler;
