import React, {useEffect, useState} from 'react';
import {FieldErrors, useFieldArray, useForm, UseFormRegister} from 'react-hook-form';
import {ErrorFieldMessage, Input, InputGroup, Tooltip} from '@sb1/ffe-form-react';
import {ActionButton, ButtonGroup, TaskButton, TertiaryButton} from '@sb1/ffe-buttons-react';
import {gyldigDato, gyldigeNavn} from '~/components/validering/regler';
import {DividerLine, Heading3} from '@sb1/ffe-core-react';
import {
    hjelpArbeidsdyktig,
    hjelpArslonn,
    hjelpFodselsnummer,
    hjelpStillingsprosent
} from '~/components/ansatte/hjelpetekster';
import TrackedButtons from '~/components/tracking/TrackedButtons';
import {Ansatt, ansattMedDefaultVerdier} from '~/components/tilbud/Tilbud';
import useSkjemafeil from '~/components/tracking/useSkjemafeil';
import {fjernMellomrom, gyldigeFodselsnumre} from '~/components/validering/fodselsnummer';
import {Hjelpetekst, HjelpetekstProps} from '~/components/produkt/Hjelpetekst';
import {maksAntallAnsatte} from "~/App";
import RadioSwitchJaNei from "~/components/felles/RadioSwitchJaNei";
import {z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {zString} from "~/components/validering/validering";
import {Icon} from "@sb1/ffe-icons-react";
import PlussIkon from "@sb1/ffe-icons/icons/open/300/md/add.svg";
import {Datepicker} from "@sb1/ffe-datepicker-react";

interface AnsattListe {
    ansatte: Ansatt[];
}

export interface AnsatteskjemaProps {
    ansatte: Ansatt[];
    beregner: boolean;
    utforSjekkPris: (ansatte: Ansatt[]) => void;
    knappetekst: string;
    dsfFeil: boolean[];
}

const valideringAnsatte = z.object({
    ansatte: z.array(z.object({
        fnr: zString('Fødselsnummer må fylles ut')
            .transform(fjernMellomrom)
            .refine(gyldigeFodselsnumre, 'Ugyldig fødselsnummer'),
        fornavn: zString('Fornavn må fylles ut')
            .regex(gyldigeNavn, 'Ugyldig fornavn'),
        etternavn: zString('Etternavn må fylles ut')
            .regex(gyldigeNavn, 'Ugyldig etternavn'),
        lonn: z.number({ required_error: 'Årslønn må fylles ut', invalid_type_error: 'Årslønn må være et tall mellom 0 og 10.000.000' })
            .int('Oppgi et heltall')
            .min(0, 'Ugyldig verdi')
            .max(10000000, 'Årslønn må være et tall mellom 0 og 10.000.000'),
        stillingspros: z.number({required_error: 'Stillingsprosent må fylles ut', invalid_type_error: 'Oppgi et heltall'})
            .int('Oppgi et heltall')
            .min(0, 'Må være mellom 0 og 100')
            .max(100, 'Må være mellom 0 og 100'),
        ansattdato: zString('Ansattdato må fylles ut')
            .regex(gyldigDato, 'Ugyldig ansattdato'),
        arbeidsdyktig: z.boolean({invalid_type_error: 'Arbeidsdyktig må fylles ut'}),
        eier: z.boolean({invalid_type_error: 'Eierskap må fylles ut'}),
    }))
})

export interface VisHjelpetekst {
    hjelpetekst: HjelpetekstProps;
    index: number;
}

interface InputfeltProps {
    index: number;
    felt: keyof Ansatt;
    label: string;
    tooltip?: React.ReactElement;
    type?: string;
    options?: object;
    register: UseFormRegister<AnsattListe>;
    errors: FieldErrors<AnsattListe>;
}

const Inputfelt: React.FunctionComponent<InputfeltProps> = ({index, felt, label, tooltip, type, options, register, errors}) => {
    return <InputGroup
        label={label}
        tooltip={tooltip}
        fieldMessage={errors?.ansatte?.[index]?.[felt]?.message}
        className="ksmb-ansattinfo-input-group ksmb-ansattinfo-input-group--inline-block"
    >
        <Input
            {...register(`ansatte.${index}.${felt}`, options)}
            type={type}
            className="ksmb-input-field ksmb-input-field--sm"
            autoComplete="on"
        />
    </InputGroup>
}

const Ansatteskjema: React.FunctionComponent<AnsatteskjemaProps> = ({utforSjekkPris, beregner, knappetekst, ansatte, dsfFeil}) => {
    const [visHjelpetekst, setVisHjelpetekst] = useState<VisHjelpetekst | null>(null);
    const [_feilmelding, setFeilmelding] = useSkjemafeil('ansattinfo');

    const { register, control, handleSubmit, setValue, setFocus, watch, formState: { errors } } = useForm<AnsattListe>({
        resolver: zodResolver(valideringAnsatte),
        defaultValues: { ansatte }
    });

    const { fields, append, remove } = useFieldArray<AnsattListe>({
        control,
        name: 'ansatte'
    });

    useEffect(() => {
        setFocus(`ansatte.0.fnr`);
    }, [setFocus])

    useEffect(() => {
        if (errors.ansatte) {
            setFeilmelding('Valideringsfeil i skjemaet');
        }
    }, [errors, setFeilmelding]);

    const toggleHjelpetekst = (onsketTekst: VisHjelpetekst) => {
        if (visHjelpetekst && visHjelpetekst.index === onsketTekst.index && visHjelpetekst.hjelpetekst === onsketTekst.hjelpetekst) {
            setVisHjelpetekst({hjelpetekst: onsketTekst.hjelpetekst, index: -1 });
        } else {
            setVisHjelpetekst(onsketTekst);
        }
    };

    const onSubmit = (formValues: AnsattListe) => {
        utforSjekkPris(formValues.ansatte);
    }

    const visTooltip = (index, hjelpetekst) => {
        return <Tooltip onClick={() => toggleHjelpetekst({ index, hjelpetekst })}></Tooltip>
    }

    return <form onSubmit={handleSubmit(onSubmit)}>
        <div>
            {fields.map((field, index) => {
                return (
                    <div key={field.id}>
                        <div className="ksmb-ansattinfo_ansatt-header">
                            <Heading3 lookLike={6} className="ksmb-ansattinfo-h2">
                                Ansatt {index + 1}
                            </Heading3>
                            {fields.length > 1 && (
                                <TrackedButtons steg={'ansattinformasjon'} knappNavn="fjern ansatt">
                                    <TertiaryButton type="button" onClick={() => remove(index)} data-testid="fjern">
                                        Fjern
                                    </TertiaryButton>
                                </TrackedButtons>
                            )}
                        </div>
                        <fieldset className="ffe-fieldset">
                            <Inputfelt index={index} felt={'fnr'} label={"Fødselsnummer"} register={register} errors={errors} tooltip={visTooltip(index, hjelpFodselsnummer)} />
                            <Inputfelt index={index} felt={'fornavn'} label={"Fornavn"} register={register} errors={errors} />
                            <Inputfelt index={index} felt={'etternavn'} label={"Etternavn"} register={register} errors={errors} />
                            <Inputfelt index={index} felt={'lonn'} label={"Årslønn"} register={register} errors={errors} tooltip={visTooltip(index, hjelpArslonn)} type="number" options={{ valueAsNumber: true }} />
                            <InputGroup label="Stillingsprosent"
                                tooltip={visTooltip(index, hjelpStillingsprosent)}
                                fieldMessage={errors?.ansatte?.[index]?.stillingspros?.message}
                                className="ksmb-ansattinfo-input-group ksmb-ansattinfo-input-group--inline-block">
                                {inputProps => (
                                    <div className="ksmb-input-container--block">
                                        <Input type="number" {...register(`ansatte.${index}.stillingspros`, {valueAsNumber: true })}
                                            {...inputProps}
                                            className="ksmb-input-field ksmb-input-field--inline ksmb-input-field--xs"
                                            defaultValue={100}
                                            autoComplete="on" /> %
                                    </div>
                                )}
                            </InputGroup>
                            <InputGroup
                                label="Ansattdato"
                                className="ksmb-ansattinfo-input-group ksmb-ansattinfo-input-group--inline-block ksmb-input-field--sm"
                                fieldMessage={errors?.ansatte?.[index]?.ansattdato?.message}
                            >
                                {inputProps => (
                                    <Datepicker
                                        value={watch(`ansatte.${index}.ansattdato`)}
                                        onChange={date => setValue(`ansatte.${index}.ansattdato`, date, {shouldValidate: true})}
                                        {...inputProps}
                                    />)
                                }
                            </InputGroup>

                            {dsfFeil && dsfFeil[index] && !errors?.ansatte?.[index] &&
                                <ErrorFieldMessage>
                                    Personen ble ikke funnet i folkeregisteret. Korriger navn eller fødselsnummer.
                                </ErrorFieldMessage>
                            }
                            <RadioSwitchJaNei
                                name={`ansatte.${index}.arbeidsdyktig`}
                                label="Arbeidsdyktig"
                                selectedValue={watch(`ansatte.${index}.arbeidsdyktig`)}
                                setValue={setValue}
                                className="ksmb-ansattinfo-input-group ksmb-ansattinfo-input-group--inline"
                                tooltip={visTooltip(index, hjelpArbeidsdyktig)}
                                fieldMessage={errors?.ansatte?.[index]?.arbeidsdyktig?.message}
                            />
                            <RadioSwitchJaNei
                                name={`ansatte.${index}.eier`}
                                label="Eier mer enn 10% av bedriften"
                                selectedValue={watch(`ansatte.${index}.eier`)}
                                setValue={setValue}
                                className="ksmb-ansattinfo-input-group ksmb-ansattinfo-input-group--inline ksmb-radio-button-input-group--uten-tooltip"
                                fieldMessage={errors?.ansatte?.[index]?.eier?.message}
                            />
                        </fieldset>
                        <Hjelpetekst
                            vis={visHjelpetekst?.index === index}
                            tekst={visHjelpetekst?.hjelpetekst}
                            className={'ksmb-ansattinfo-hjelpetekst'}
                        />
                    </div>
                )
            }
            )}
            {fields.length < maksAntallAnsatte && (
                <>
                    <DividerLine />
                    <ButtonGroup
                        thin={true}
                        ariaLabel="Knapp for å legge til en ansatt til"
                        className="ksmb-button-group--medium-thin ksmb-button-group--flex"
                    >
                        <TrackedButtons steg={'ansattinformasjon'} knappNavn="legg til ansatt">
                            <TaskButton type="button" icon={<Icon fileUrl={PlussIkon} size={"md"}/>}
                                onClick={() => append({...ansattMedDefaultVerdier})}
                                data-testid="legg til">
                                Legg til ansatt
                            </TaskButton>
                        </TrackedButtons>
                    </ButtonGroup>
                </>
            )}
            <ButtonGroup
                thin={true}
                ariaLabel="Knapp for å beregne pris"
                className="ksmb-button-group--medium-thin ksmb-button-group--flex"
            >
                <TrackedButtons steg={'ansattinformasjon'} knappNavn="sjekk pris">
                    <ActionButton type="submit"
                        isLoading={beregner}
                        ariaLoadingMessage="Vennligst vent"
                        data-testid="sjekk-pris">
                        {knappetekst}
                    </ActionButton>
                </TrackedButtons>
            </ButtonGroup>
        </div>
    </form>
}
export default Ansatteskjema;
