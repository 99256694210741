import { z } from "zod";
import { zString } from "~/components/validering/validering";

export const requiredMelding = 'Må besvares før du kan gå videre';

export const valideringProduktdetaljer = (endre: boolean, uforeDekning: boolean, flytting: boolean) => z.object({
    lavSats: z.number({invalid_type_error: 'Oppgi et heltall', required_error: 'Oppgi et heltall'})
        .min(2, 'Må være mellom 2 og 7')
        .max(7, 'Må være mellom 2 og 7'),
    hoySats: z.number(),
    uforeDekning:
        (uforeDekning ?
            z.boolean({invalid_type_error: requiredMelding, required_error: requiredMelding})
            :
            z.any().optional()
        ),
    startdato: z.string().optional(),
    flytteSelskap:
        (flytting ?
            zString(requiredMelding)
            :
            z.string().optional()
        ),
    flytteSelskapTekst: z.string().optional(),
    flyttesFraAvtalenummer: z.string().optional(),
    dagligLederInnehaverEllerStyreleder: z.boolean().nullable()
}).superRefine(({flytteSelskap, flytteSelskapTekst, dagligLederInnehaverEllerStyreleder}, ctx) => {
    if (flytting && flytteSelskap === "-1" && !flytteSelskapTekst) {
        ctx.addIssue({code: "custom", message: requiredMelding, path: ["flytteSelskapTekst"]})
    }
    if (!endre && dagligLederInnehaverEllerStyreleder === null) {
        ctx.addIssue({code: "custom", message: requiredMelding, path: ["dagligLederInnehaverEllerStyreleder"]})
    }
});
