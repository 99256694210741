import React from 'react';
import { useForm } from 'react-hook-form';
import { Tilbud } from '~/components/tilbud/Tilbud';
import { Grid, GridCol, GridRow } from '@sb1/ffe-grid-react';
import { Heading2 } from '@sb1/ffe-core-react';
import { Checkbox, Input, InputGroup } from '@sb1/ffe-form-react';
import { BulletList, BulletListItem } from '@sb1/ffe-lists-react';
import { ActionButton, ButtonGroup } from '@sb1/ffe-buttons-react';
import epost from "~/components/validering/epost";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

interface Props {
    loading: boolean;
    tilbud: Tilbud;
    signerTilbud: (values: SigneringsInput) => void;
}

const valideringSigneringsInput = z.object({
    epost: epost(true),
    akseptVilkar: z.boolean()
        .refine(x => x, 'Vennligst bekreft at du har lest og forstått forutsetningene for kjøpet')
});

export type SigneringsInput = z.infer<typeof valideringSigneringsInput>;

const SigneringSkjema: React.FunctionComponent<Props> =
    ({ tilbud, loading, signerTilbud }) => {
        const { register, handleSubmit, formState: { errors } } = useForm<SigneringsInput>({
            resolver: zodResolver(valideringSigneringsInput),
            defaultValues: { epost: tilbud.epost || '', akseptVilkar: false }
        });

        return (
            <form onSubmit={handleSubmit(signerTilbud)}>
                <Grid>
                    <GridRow className='ksmb--padding-top'>
                        <GridCol lg={{ cols: 8, offset: 2 }} className='ksmb-grid-col--padding-bottom'>
                            <Heading2>Hvor skal vi sende kvittering?</Heading2>
                            <InputGroup label="E-post"
                                fieldMessage={errors?.epost?.message}
                            >
                                <Input type="email" className="ksmb-input-field ksmb-input-field--lg" {...register('epost')} />
                            </InputGroup>
                        </GridCol>
                    </GridRow>
                    <GridRow background={'frost-30'} className={'ksmb--padding-top'}>
                        <GridCol lg={{ cols: 8, offset: 2 }}>
                            <Heading2>Forutsetninger for kjøp</Heading2>
                            <BulletList>
                                <BulletListItem>Jeg bekrefter at opplysingene jeg har gitt er korrekte.</BulletListItem>
                                <BulletListItem>Jeg godtar vilkårene.</BulletListItem>
                            </BulletList>
                            <InputGroup fieldMessage={errors?.akseptVilkar?.message}>
                                <Checkbox {...register("akseptVilkar")}
                                    aria-invalid={errors?.akseptVilkar?.message !== undefined}>
                                    Jeg har lest og forstått forutsetningene for kjøpet
                                </Checkbox>
                            </InputGroup>
                            <ButtonGroup ariaLabel="Knapp for å gå videre til neste steg">
                                <ActionButton type="submit" isLoading={loading}>
                                    Se over og signer tilbudet
                                </ActionButton>
                            </ButtonGroup>
                        </GridCol>
                    </GridRow>
                </Grid>
            </form>
        );
    };

export default SigneringSkjema;
