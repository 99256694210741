import React, {useState} from 'react';
import {useNavigate} from 'react-router';
import TilbudOppsummering from '~/components/oppsummering/TilbudOppsummering';
import SendEpost, {EpostInput} from '~/components/oppsummering/SendEpost';
import {oppdaterTilbud, sendTilbudsEpost} from '~/components/tilbud/TilbudService';
import {useErrorHandler} from '~/components/felles/ErrorHandler';
import {useInnloggetBruker} from '~/components/felles/InnloggetBruker';
import useTilbud from '~/components/tilbud/useTilbud';
import {Skjemadata, trackFormStepStarted} from '@sparebank1/pensjon-bm-analytics';
import {useTrackPage} from '~/components/tracking/useTrackPage';

export const SKJEMA: Skjemadata = {navn: 'Oppsummering', type: '', skjematype: 'KSMB'};
export const steg = {
    VIS_OPPSUMMERING: {navn: 'Vis oppsummering', nummer: 1},
    OPPDATER_TILBUD: {navn: 'Oppdater tilbud', nummer: 2},
    SEND_EPOST: {navn: 'Send tilbud på epost', nummer: 3}
};

const Oppsummering: React.FunctionComponent = () => {

    useTrackPage(SKJEMA, steg.VIS_OPPSUMMERING);

    const {tilbudId, tilbud} = useTilbud();
    const [loading, setLoading] = useState<boolean>(false);
    const {catchError} = useErrorHandler();
    const { innlogget } = useInnloggetBruker();
    const navigate = useNavigate();

    function sendEpost(values: EpostInput) {
        trackFormStepStarted(steg.OPPDATER_TILBUD);
        setLoading(true);
        tilbud.epost = values.epost;
        oppdaterTilbud(tilbudId, tilbud).then(() => {
            trackFormStepStarted(steg.SEND_EPOST);
            return sendTilbudsEpost(tilbudId);
        }).then(() => {
            navigate(`/epostsendt/${tilbudId}`);
        }).catch((error) => {
            setLoading(true);
            catchError(error);
        });
    }

    const dsfOk = innlogget ? tilbud.ansatte.every(ansatt => ansatt.dsfOk) : true;

    return <>
        <TilbudOppsummering dsfOk={dsfOk} />
        <SendEpost tilbud={tilbud} onSubmit={sendEpost} loading={loading} />
    </>
}
export default Oppsummering;
