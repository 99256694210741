import React, {useEffect, useState} from 'react';
import {useErrorHandler} from '~/components/felles/ErrorHandler';
import {oppdaterTilbud,} from '~/components/tilbud/TilbudService';
import SigneringSkjema, {SigneringsInput} from '~/components/signering/SigneringSkjema';
import TilbudOppsummering from '~/components/oppsummering/TilbudOppsummering';
import {dsfSjekk} from '~/components/ansatte/DsfService';
import {Ansatt} from '~/components/tilbud/Tilbud';
import { Spinner } from '@sb1/ffe-spinner-react';
import {Grid, GridCol} from '@sb1/ffe-grid-react';
import useTilbud from '~/components/tilbud/useTilbud';
import {Skjemadata, trackFormStepStarted} from '@sparebank1/pensjon-bm-analytics';
import {useTrackPage} from '~/components/tracking/useTrackPage';
import {useSettings} from '~/components/felles/Settings';

const erDsfOk = ansatte => !ansatte.some(ansatt => !ansatt.dsfOk);

const SKJEMA: Skjemadata = { navn: 'Signering', type: '', skjematype: 'KSMB' };
const steg = {
    VIS_OPPSUMMERING: { navn: 'Sjekk om kunden har rett til å signere', nummer: 1 },
    SJEKK_DSF: { navn: 'Sjekk folkeregisteret', nummer: 2 },
    OPPDATER_TILBUD: { navn: 'Oppdater tilbud', nummer: 3 },
};

const Signering: React.FunctionComponent = () => {

    const { tilbudId, tilbud } = useTilbud();
    const { setFeilmelding, feilmelding, catchError } = useErrorHandler();
    const [loading, setLoading] = useState<boolean>(false);
    const [dsfOk, setDsfOk] = useState<boolean | null>(null);
    const { kfb_url } = useSettings();

    useTrackPage(SKJEMA, steg.VIS_OPPSUMMERING);

    useEffect(() => {
        if (!erDsfOk(tilbud.ansatte)) {
            trackFormStepStarted(steg.SJEKK_DSF);
            dsfSjekk(tilbud.ansatte).then((dsfAnsatte: Ansatt[]) => {
                tilbud.ansatte = dsfAnsatte;
                setDsfOk(erDsfOk(tilbud.ansatte));
            }).catch(e => {
                catchError(e);
            });
        } else {
            setDsfOk(true);
        }
    }, [tilbudId]); // eslint-disable-line react-hooks/exhaustive-deps

    function startSignering(signeringsInput: SigneringsInput) {
        if (signeringsInput.akseptVilkar && tilbud) {
            tilbud.epost = signeringsInput.epost;
            setLoading(true);
            trackFormStepStarted(steg.OPPDATER_TILBUD);
            oppdaterTilbud(tilbudId, tilbud).then(() => {
                window.location.href = `${kfb_url}/kfb/frontend/kundeklient/${tilbud.orgnr}/tilbud/signering/${tilbudId}/tilbud`;
            }).catch(error => {
                setLoading(false);
                if (error.status === 403) {
                    setFeilmelding('Vi kan ikke bekrefte din rolle mot Brønnøysundregistrene. Fyll inn følgende informasjon så kontakter vi deg. Bestillingen er lagret og kan hentes opp når tilgang er gitt.');
                } else {
                    catchError(error);
                }
            });
        }
    }

    if (feilmelding) {
        return null;
    }

    if (dsfOk === null) {
        return <Grid>
            <GridCol center={true}>
                <Spinner large={true} loadingText="Sjekker folkeregisteret..." />
            </GridCol>
        </Grid>
    }

    return <>
        <TilbudOppsummering dsfOk={dsfOk} />
        {tilbud && dsfOk &&
            <SigneringSkjema tilbud={tilbud} signerTilbud={startSignering} loading={loading} />
        }
    </>
};

export default Signering;
